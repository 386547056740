@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap");

@keyframes slideOutLeft {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.slideOutLeft {
  animation-name: slideOutLeft;
  animation-duration: 0.3s;
  animation-timing-function: ease;
  animation-fill-mode: both;
}

.slideInLeft {
  animation-name: slideInLeft;
  animation-duration: 0.3s;
  animation-timing-function: ease;
  animation-fill-mode: both;
}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
}

.fadeOut {
  animation-name: fadeOut;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
}
